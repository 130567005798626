// ** React Imports
import { memo } from "react";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// ** Custom Components
import Block from "components/layout/Block";
import Section from "components/layout/Section";
import MilionNumberPresenter from "components/other/MilionNumberPresenter";

// ** Utils & Helpers
import pseudoFormat from "utils/pseudoFormat";

// ** Styles
import { breakpoints } from "styles/grid";

// #####################################################

const Box = styled("div")`
	flex: 1;
	position: relative;
	text-align: center;
	padding: 0.75rem;
	box-shadow: var(--dz-sys-card-shadow);
	border: 1px solid var(--color-border);
	background-color: ${({ theme: { colors } }) => colors.whiteTrue};
	border-radius: var(--dz-sys-card-border-radius);

	@media (min-width: ${breakpoints.tablet}px) {
		padding: 1rem;
	}
`;

const TopText = styled("p")`
	font-size: 0.8rem;
	line-height: 1.5rem;

	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 1.2rem;
	}
`;

const MainText = styled("p")`
	font-size: 1.5rem;
	font-weight: bold;
	white-space: nowrap;

	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 2.3rem;
	}
`;

const BottomText = styled("p")`
	font-size: 0.8rem;
	line-height: 1rem;

	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 1.2rem;
	}
`;

// #####################################################

const StatisticsSection = () => {
	const { t } = useTranslation(["common", "landing"]);

	const usersOnlineCount = useSelector(
		(state) => state.global.statistics.online
	);
	const mediaTotal = useSelector(
		(state) => state.global.statisticsMain.media
	);
	const registeredUsers = useSelector(
		(state) => state.global.statisticsMain.users
	);

	const isDarkMode = useSelector((state) => state.global.darkMode.darkMode);

	// #####################################################

	return (
		<Section>
			<Block flex withColumnGap>
				<Box>
					<TopText>{t("siteStats.currently")}</TopText>
					<MainText>{pseudoFormat(usersOnlineCount)}</MainText>
					<BottomText>{t("siteStats.usersOnline")}</BottomText>
				</Box>

				<Box>
					<TopText>{t("siteStats.moreThan")}</TopText>
					<MainText>
						{<MilionNumberPresenter value={registeredUsers} />}
					</MainText>
					<BottomText>{t("siteStats.registeredUsers")}</BottomText>
				</Box>

				<Box>
					<TopText>{t("siteStats.moreThan")}</TopText>
					<MainText>
						{<MilionNumberPresenter value={mediaTotal} />}
					</MainText>
					<BottomText>{t("siteStats.addedMedia")}</BottomText>
				</Box>
			</Block>
		</Section>
	);
};

// #####################################################

export default memo(StatisticsSection);

// #####################################################
