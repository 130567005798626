export default (value, option = {}) => {
	if (option.sep == null) {
		option.sep = "\u00A0";
	}

	if (option.override == null) {
		option.override = true;
	}

	if (value == null || Number.isNaN(value)) {
		return !option.override ? value : "";
	}

	const result = new Intl.NumberFormat("fr-FR", { style: "decimal" }).format(
		value
	);

	return result.split(/\s+/).join(option.sep);
};
